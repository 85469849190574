<template>
  <div>
    <!-- TODO: FILTRO EMPRESA MARCA PATENTE  -->
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            title="Sincronizar"
            color="success"
            small
            fab
            @click="handleSync"
            ><v-icon>fas fa-sync</v-icon></v-btn
          >
          <v-expansion-panel-header
            class="py-0 px-4 text-h5"
            style="cursor: default"
          >
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row class="d-flex align-center">
                <v-col cols="12" sm="3" md="2" class="pt-1">
                  Empresa
                  <v-autocomplete
                    @change="set_marca()"
                    clearable
                    v-model="filtro.empresa"
                    item-value="id"
                    item-text="nombre"
                    :items="empresasFiltradas"
                    hide-details
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="pt-1">
                  Marca
                  <v-autocomplete
                    v-model="filtro.marca"
                    clearable
                    item-value="id"
                    item-text="nombre"
                    :items="marcasFiltradas"
                    hide-details
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="pt-1">
                  Patente
                  <v-text-field
                    v-model="filtro.patente"
                    clearable
                    item-value="id"
                    item-text="nombre"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col v-if="permiso" cols="3" sm="3" md="2" class="pt-1">
                  <v-switch
                    v-model="filtro.switch"
                    class="ml-4"
                    :label="'Inhabilitados'"
                    hide-details
                  ></v-switch>
                </v-col>

                <v-col
                  cols="12"
                  sm="3"
                  :md="permiso ? 4 : 6"
                  class="d-flex justify-end pb-sm-0"
                >
                  <BtnFiltro @clear="limpiarFiltro()" />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-btn @click="exportar()" class="mt-2" color="green" dark>
        <v-icon left> fas fa-file-download </v-icon>
        exportar
      </v-btn>

      <v-btn class="mt-2 ml-2" color="blue" dark @click="importar()">
        <v-icon left> fas fa-file-import </v-icon>
        importar
      </v-btn>
      <v-bottom-sheet v-model="sheet">
        <v-sheet class="text-center" height="auto">
          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 1;
              padding: 0 12px;
            "
          >
            <v-btn
              style="align-self: flex-end"
              class=""
              icon
              @click="sheet = false"
            >
              <v-icon> fas fa-times </v-icon>
            </v-btn>
            <div class="mb-2">
              <FileDropzone
                :files.sync="archivo"
                :tipos="tipos_validos"
                :multiple="false"
                :max_size="20"
              />
            </div>
          </div>
        </v-sheet>
      </v-bottom-sheet>
      <v-data-table
        class="cebra elevation-2 mt-2"
        item-key="id"
        sort-by="id"
        :headers="headers"
        :items="vehiculosFiltrados"
        :loading="load"
        sort-desc
        dense
        :search="search"
      >
        <!-- Buscador en el datatable -->
        <template v-slot:top>
          <v-row
            class="d-flex pa-2 h-mobile"
            style="justify-content: space-between; height: 4.3rem"
            no-gutters
            hide-details
          >
            <v-col cols="12" sm="5" style="display: flex; gap: 1rem">
              <v-text-field
                v-model="calculados.costoFinanciero"
                type="number"
                dense
                label="Costo Financiero"
                outlined
                :rules="[validarPorcentaje]"
                suffix="%"
              ></v-text-field>
              <v-text-field
                v-model="calculados.margen"
                type="number"
                dense
                label="Margen"
                outlined
                :rules="[validarPorcentaje]"
                suffix="%"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3">
              <SearchDataTable v-model="search" />
            </v-col>
          </v-row>
        </template>

        <template v-slot:[`item.Patente`]="{ item }">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <span>
              {{ item.Patente }}
            </span>
            <v-tooltip right color="indigo darken-1">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" dark v-bind="attrs" v-on="on">
                  <v-icon small color="indigo lighten-2">
                    fas fa-info-circle
                  </v-icon>
                </v-btn>
              </template>
              <div
                style="
                  font-weight: 600;
                  padding: 10px 18px;
                  display: flex;
                  flex-direction: column;
                "
              >
                <h2 class="pb-2">Información del vehículo</h2>
                <span> Empresa: {{ item.empresa }} </span>
                <span> Ex dueño: {{ item.Ex_Duenio }} </span>
                <span> Ubicación: {{ item.Ubicacion }} </span>
                <span> Modelo: {{ item.Modelo }} </span>
                <span> Año: {{ item.Anio }} </span>
                <span> Km: {{ item.KM }} </span>
                <span> Color: {{ item.Color }} </span>
                <span> Transmisión: {{ item.Transmision }} </span>
                <span> Combustible: {{ item.Combustible }} </span>
                <span> Puertas: {{ item.Puertas }} </span>
              </div>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:header.Modelo="{ props }"> Modelo </template>

        <template v-slot:[`item.Marca`]="{ item }">
          <span style="font-size: 12px; width: 30px">
            {{ item.Marca }}
          </span>
        </template>
        <template v-slot:[`item.meses`]="{ item }">
          <span style="font-size: 12px; width: 30px">
            {{ item.meses }}
          </span>
        </template>
        <template v-slot:[`item.dias`]="{ item }">
          <span style="font-size: 12px; width: 30px">
            {{ item.dias }}
          </span>
        </template>

        <template v-slot:[`item.PrecioDeToma`]="{ item }">
          {{ item.PrecioDeToma | formatMoney }}
        </template>

        <template v-slot:[`item.costoFinanciero`]="{ item }">
          {{ item.costoFinanciero | formatMoney }}
        </template>

        <template v-slot:[`item.margen`]="{ item }">
          {{ item.margen | formatMoney }}
        </template>
        <template v-slot:[`item.precioRevista`]="{ item }">
          {{ item.precioRevista | formatMoney }}
        </template>
        <template v-slot:[`item.precioFinal`]="{ item }">
          {{ item.precioFinal | formatMoney }}
        </template>

        <template v-slot:[`item.Modelo`]="{ item }">
          <span style="font-size: 11px; text-overflow: ellipsis">
            {{ item.Modelo }}
          </span>
        </template>

        <template v-slot:[`item.Situacion`]="{ item }">
          <span style="font-size: 12px; width: 30px">
            {{ item.Situacion }}
          </span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div
            style="
              display: flex;
              gap: 3px;
              align-items: center;
              justify-content: center;
            "
          >
            <v-icon
              color="success"
              title="Editar"
              class="mr-2"
              small
              @click="abrir_modal_editar(item, false)"
            >
              fas fa-pen
            </v-icon>
            <v-icon
              color="info"
              title="Comentarios"
              class="mr-2"
              small
              @click="abrir_modal_info(item)"
              :disabled="filtro.switch"
            >
              fas fa-comment-dots
            </v-icon>

            <BtnConfirmar
              v-if="!filtro.switch"
              :icono="'fas fa-ban'"
              :color="'error'"
              :title="'Inhabilitar'"
              :texto="`¿Desea inhabilitar el vehiculo con patente: <strong>${item.Patente}</strong>?`"
              :icon_button="true"
              :small="true"
              @action="inhabilitar(item)"
            />
            <!-- <v-icon
              v-if="filtro.switch"
              :title="'Habilitar'"
              color="success"
              title="Habilitar"
              class="mr-2"
              small
              @click="abrir_modal_editar(item, true)"
              >fas fa-check</v-icon
            > -->
            <!-- @action="!item.Habilitado ? habilitar(item) : inhabilitar(item)" -->
          </div>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
      <div
        style="
          width: 100%;
          margin-top: 0.6rem;
          display: flex;
          justify-content: end;
          gap: 1rem;
        "
      >
        <BtnConfirmar
          nombre="Publicar precios"
          title="publicar precios en AutoDealer"
          color="info"
          :loading="$store.state.loading"
          @action="publicarPreciosAutodealer()"
          icono="fas fa-file-upload"
        />

        <BtnConfirmar
          nombre="Publicar en web"
          :loading="$store.state.loading"
          @action="publicarPrecios()"
          icono="fas fa-file-upload"
        />
      </div>
    </v-col>

    <ModalInfo v-model="dialogInfo" :vehiculo="vehiculo" />
    <ModalEditar
      v-model="dialogEditar"
      :vehiculo="vehiculo"
      @actualizarInfoEditar="handleActualizarInfoEditar"
      :habilitar="habilitar"
      :permiso="permiso"
      @habilitado="handleHabilitado($event)"
      @inhabilitado="handleInhabilitado($event)"
    />
  </div>
</template>

<script>
import FileDropzone from '../../components/util/FileDropzone.vue'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import ModalInfo from '../../components/usados/ModalInfo.vue'
import ModalEditar from '../../components/usados/ModalEditar.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import moment from 'moment'
import { format_money_round } from '../../util/utils'
import FileSaver from 'file-saver'
import { read, utils, write } from 'xlsx'
import { mapState } from 'vuex'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'

export default {
  data() {
    return {
      panel: 0,
      search: '',
      load: false,
      sheet: false,
      archivo: [],
      dialogInfo: false,
      dialogEditar: false,
      habilitar: false,
      permiso: false,
      vehiculo: {},
      tipos_validos: ['.xls', '.xlsx', '.ods'],
      filtro: {
        empresa: null,
        marca: null,
        patente: null,
        switch: false
      },
      calculados: {
        costoFinanciero: null,
        margen: null
      },
      marcas: [],
      marcasFiltradas: [],
      headers: [
        { text: 'Patente', value: 'Patente' },
        { text: 'Marca', value: 'Marca' },
        { text: 'Modelo', value: 'ModeloBase' },
        { text: 'Meses', value: 'meses', width: '70', align: 'center' },
        { text: 'Días', value: 'dias' },
        { text: 'Costo Taller', value: 'montoTotal' },
        { text: 'Precio de toma', value: 'PrecioDeToma' },
        { text: 'Costo Financiero', value: 'costoFinanciero' },

        { text: 'Margen', value: 'margen' },
        { text: 'Precio revista', value: 'precioRevista' },
        { text: 'Precio publicado', value: 'precioFinal' },

        { text: 'Situación', value: 'Situacion' },
        {
          text: 'Acciones',
          value: 'actions',
          sortable: false,
          width: '10',
          align: 'center'
        }
      ],
      vehiculos: [],
      infoVehiculos: [],
      vehiculosImportados: [],
      vehiculosSeteados: [],
      vehiculosFiltrados: [],
      empresas: [],
      empresasFiltradas: [],
      preciosVigentes: [],
      vehiculosEnVigencia: []
    }
  },

  async created() {
    await this.getVehiculos()
    await this.$store.dispatch('usados/getPermisoHabilitar').then(res => {
      if (res.exito) {
        this.permiso = res.permiso === 1 ? true : false
      }
    })
  },

  methods: {
    async getVehiculos() {
      this.$store.state.loading = true
      await this.getInfo()
      await this.getPreciosVigentes()

      await this.$store.dispatch('usados/getVehiculosPrecios').then(res => {
        if (res.exito === 1) {
          this.vehiculos = res.data
          this.vehiculos.forEach(vehiculo => {
            vehiculo.precioRevista = null
            vehiculo.precioFinal = null
            const precioVigenteVehiculo = this.preciosVigentes.filter(
              precio => precio.Usado_id === vehiculo.Usado_id
            )

            if (precioVigenteVehiculo.length > 0) {
              if (precioVigenteVehiculo[0].Usado_id == vehiculo.Usado_id) {
                vehiculo.precioRevista =
                  precioVigenteVehiculo[0].precio_referencia
                vehiculo.precioFinal = precioVigenteVehiculo[0].Precio
              }
            }
            const fechaMoment = moment(vehiculo.Alta)
            const fechaActual = moment()
            const diferenciaMeses = fechaActual.diff(fechaMoment, 'months')
            const diferenciaDias = fechaActual.diff(fechaMoment, 'days')
            vehiculo.dias = diferenciaDias
            vehiculo.meses = diferenciaMeses
            vehiculo.costoFinanciero = null
          })
          // Formateo
          this.vehiculosSeteados = structuredClone(this.vehiculos)
          this.vehiculosSeteados.forEach(vehiculo => {
            this.marcas.push(vehiculo.Marca.trim())
            this.marcasFiltradas = Array.from(new Set(this.marcas))
            vehiculo.costoFinanciero = null
            vehiculo.margen = null
            vehiculo.total = null

            this.empresas = [
              {
                id: 1,
                nombre: 'DYCAR'
              },
              {
                id: 2,
                nombre: 'EURODYCAR'
              },
              {
                id: 3,
                nombre: 'GDB'
              },
              {
                id: 5,
                nombre: 'RADA'
              }
            ]

            this.empresasFiltradas = this.empresas

            // Set Empresa de vehiculos
            switch (vehiculo.Empresa_id) {
              case 1:
                vehiculo.empresa = 'DYCAR'
                break
              case 2:
                vehiculo.empresa = 'EURODYCAR'
                break
              case 3:
                vehiculo.empresa = 'GDB'
                break
              case 5:
                vehiculo.empresa = 'RADA'
                break
            }
            // Set info
            const info = this.infoVehiculos.filter(
              info => info.UsadoID === vehiculo.Usado_id
            )
            if (info.length > 0) {
              vehiculo.memos = info[0].Memos.split(';')
              vehiculo.montoTotal = format_money_round(info[0].MontoTotal)
            }
          })
        }
        this.vehiculosFiltrados = this.vehiculosSeteados
      })
      this.$store.state.loading = false
    },

    async getPreciosVigentes() {
      await this.$store.dispatch('usados/getPreciosVigentes').then(res => {
        if (res.exito == 1) {
          this.preciosVigentes = res.data
        }
      })
    },

    async getInfo() {
      await this.$store.dispatch('usados/getInfo').then(res => {
        this.infoVehiculos = res.data
      })
    },

    buscar() {
      this.search = ''
      this.calculados.costoFinanciero = null
      this.calculados.margen = null
      const { empresa, marca, patente } = this.filtro
      if (!empresa && !marca && !patente) {
        this.vehiculosFiltrados = this.vehiculosSeteados
        return
      }
      const patenteFiltrada =
        typeof patente === 'string' ? patente.toUpperCase() : patente

      this.vehiculosFiltrados = this.vehiculosSeteados.filter(vehiculo => {
        const empresaMatch = empresa === null || empresa === vehiculo.Empresa_id
        const marcaMatch =
          marca === null || marca.trim() === vehiculo.Marca.trim()
        const patenteMatch =
          patente === null || patenteFiltrada === vehiculo.Patente

        return empresaMatch && marcaMatch && patenteMatch
      })
    },

    limpiarFiltro() {
      this.filtro.empresa = null
      this.filtro.marca = null
      this.filtro.patente = null
      this.marcasFiltradas = this.marcas
    },

    set_marca() {
      this.filtro.marca = null

      if (!this.filtro.empresa || this.filtro.empresa === '') {
        this.marcasFiltradas = this.marcas
        return
      }

      const marcasPorEmpresa = Array.from(
        new Set(
          this.vehiculosSeteados
            .filter(vehiculo => vehiculo.Empresa_id === this.filtro.empresa)
            .map(vehiculo => vehiculo.Marca.trim())
        )
      )

      this.marcasFiltradas = [...marcasPorEmpresa]
    },

    handleSync() {
      this.$store.state.loading = true
      this.$store.dispatch('usados/sincronizar').then(res => {
        if (res.exito == 1) {
          window.location.reload(true)
        }
      })
    },

    abrir_modal_info(item) {
      this.dialogInfo = true
      this.vehiculo = item
    },

    abrir_modal_editar(item, habilitar) {
      this.habilitar = habilitar
      this.dialogEditar = true
      this.vehiculo = item
    },

    validarPorcentaje(value) {
      const numero = parseInt(value)
      if (numero < 0 || numero > 100)
        return 'El número debe estar entre 0 y 100'
      return true
    },

    exportar() {
      const cabecera = [
        [
          'Posicion',
          'Patente',
          'Marca',
          'Modelo',
          'Año',
          'Km',
          'Color',
          'Transmision',
          'Motor',
          'Combustible',
          'Puertas',
          'Fecha alta',
          'Meses',
          'Dias',
          'Ex dueño',
          'Situacion',
          'Empresa',
          'Ubicacion',
          'Precio de toma',
          'Costo Financiero',
          'Margen',
          'Costo de taller',
          'Total',
          'Precio revista',
          'Precio final'
        ]
      ]

      const ws = utils.aoa_to_sheet(cabecera)
      const array = []

      this.vehiculosFiltrados.forEach(vehiculo => {
        const fechaMoment = moment(vehiculo.Alta)
        const fechaFormateada = fechaMoment.format('DD/MM/YYYY')

        const infoVehiculo = this.infoVehiculos.find(
          info => info.UsadoID === vehiculo.Usado_id
        )

        let costoTaller = ''

        // El control lo tengo ya que estan sincronizados los datos
        if (infoVehiculo) {
          costoTaller = Math.trunc(infoVehiculo.MontoTotal)
        }
        // let setEmpresa = vehiculo.Empresa_id == 5 ? 'RADA' : '';
        let setEmpresa = ''

        switch (vehiculo.Empresa_id) {
          case 1: {
            setEmpresa = 'DYCAR'
            break
          }
          case 2: {
            setEmpresa = 'EURODYCAR'
            break
          }
          case 3: {
            setEmpresa = 'GDB'
            break
          }
          case 5: {
            setEmpresa = 'RADA'
            break
          }
          default: {
            setEmpresa = ''
          }
        }

        vehiculo.costoFinanciero != null && vehiculo.margen != null
          ? (vehiculo.total = vehiculo.margen + costoTaller)
          : (vehiculo.total = null)

        let datos = {
          posicion: vehiculo.Posicion,
          patente: vehiculo.Patente,
          marca: vehiculo.Marca,
          modelo: vehiculo.Modelo,
          anio: vehiculo.Anio,
          km: vehiculo.KM,
          color: vehiculo.Color,
          transmision: vehiculo.Transmision,
          motor: vehiculo.Motor,
          combustible: vehiculo.Combustible,
          puertas: vehiculo.Puertas,
          fechaAlta: fechaFormateada,
          meses: vehiculo.meses,
          dias: vehiculo.dias,
          exDueño: vehiculo.Ex_Duenio,
          situacion: vehiculo.Situacion || '',
          empresa: setEmpresa,
          ubicacion: vehiculo.Ubicacion,
          precioDeToma: vehiculo.PrecioDeToma,
          costoFinanciero: vehiculo.costoFinanciero,
          margen: vehiculo.margen,
          costoTaller: costoTaller,
          total: vehiculo.total || 0,
          precioRevista: vehiculo.precioRevista || null,
          precioFinal: vehiculo.precioFinal || null
        }
        array.push(datos)
      })

      utils.sheet_add_json(ws, array, {
        header: [
          'posicion',
          'patente',
          'marca',
          'modelo',
          'anio',
          'km',
          'color',
          'transmision',
          'motor',
          'combustible',
          'puertas',
          'fechaAlta',
          'meses',
          'dias',
          'exDueño',
          'situacion',
          'empresa',
          'ubicacion',
          'precioDeToma',
          'costoFinanciero',
          'margen',
          'costoTaller',
          'total',
          'precioRevista',
          'precioFinal'
        ],
        skipHeader: true,
        origin: -1
      })

      // Ajusto ancho de columnas automáticamente
      const columnas = Object.keys(array[0])
      columnas.forEach(columna => {
        const maxLength = array.reduce(
          (max, row) => Math.max(max, String(row[columna]).length),
          0
        )
        ws['!cols'] = ws['!cols'] || []
        ws['!cols'].push({ wch: maxLength + 2 })
      })

      const wb = utils.book_new()
      utils.book_append_sheet(wb, ws, 'Reporte')
      const wbout = write(wb, { bookType: 'xlsx', type: 'array' })
      let name = 'Usados_' + moment().format('DD/MM/YYYY') + '.xlsx'
      FileSaver.saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        name
      )
    },

    importar() {
      this.sheet = true
      if (this.archivo.length > 0) {
        this.dialogPublicar = true
      }
    },

    handleActualizarInfoEditar(item) {
      this.vehiculosFiltrados.forEach(vehiculo => {
        if (vehiculo.Usado_id === item.Usado_id) {
          vehiculo.precioFinal = item.PrecioFinal
          vehiculo.precioRevista = item.PrecioRevista
          vehiculo.Situacion = item.Situacion
          vehiculo.KM = Number(item.KM)
          vehiculo.Color = item.Color
          vehiculo.Modelo = item.Modelo
          vehiculo.Transmision = item.Transmision
          vehiculo.Motor = item.Motor
          vehiculo.Combustible = item.Combustible
          vehiculo.Puertas = item.Puertas

          if (item.Empresa_id) {
            vehiculo.Empresa_id = item.Empresa_id
          }
        }
      })
    },

    async publicarPrecios() {
      this.$store.state.loading = true
      this.limpiarFiltro()
      this.buscar()

      this.vehiculosEnVigencia = []

      this.vehiculosFiltrados.forEach(vehiculo => {
        const existeEnVigentes = this.preciosVigentes.some(
          precio => precio.Usado_id === vehiculo.Usado_id && precio.Vigente
        )

        if (existeEnVigentes) {
          this.vehiculosEnVigencia.push(vehiculo)
        }
      })

      // Si vehiculosEnVigencia es un array y no está vacío antes de enviarlo
      if (
        Array.isArray(this.vehiculosEnVigencia) &&
        this.vehiculosEnVigencia.length > 0
      ) {
        await this.$store
          .dispatch('usados/publicarPreciosUsados', this.vehiculosEnVigencia)
          .then(res => {
            if (res.exito == 1) {
              this.$swal.fire({
                icon: 'success',
                title: res.message
              })
            }
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              color: 'error',
              text: error
            })
          })
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'No hay vehículos en vigencia para publicar.',
          color: 'orange'
        })
      }

      this.$store.state.loading = false
    },

    async publicarPreciosAutodealer() {
      this.$store.state.loading = true
      this.limpiarFiltro()
      this.buscar()

      try {
        await this.$store
          .dispatch('usados/publicarPreciosAutodealer', this.vehiculosFiltrados)
          .then(res => {
            if (res.exito == 1) {
              this.$swal.fire({
                icon: 'success',
                title: res.message
              })
            } else {
              this.$store.dispatch('show_snackbar', {
                color: 'error',
                text: res.message
              })
            }
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              color: 'error',
              text: error
            })
          })
      } catch (error) {}
      this.$store.state.loading = false
    },

    // async habilitar(item) {
    //   await this.$store
    //     .dispatch('usados/habilitarVehiculo', {
    //       patente: item.Patente,
    //       familia_id: item.Empresa_id,
    //       posicion: item.Posicion
    //     })
    //     .then(res => {
    //       if (res.exito) {
    //         this.vehiculosSeteados = this.vehiculosSeteados.filter(vehiculo => {
    //           return (
    //             vehiculo.Patente != item.Patente &&
    //             vehiculo.Posicion != item.Posicion
    //           )
    //         })
    //         this.vehiculosFiltrados = this.vehiculosSeteados
    //       } else {
    //         this.$store.dispatch('show_snackbar', {
    //           color: 'error',
    //           text: res.message
    //         })
    //       }
    //     })
    // },

    handleHabilitado(item) {
      this.vehiculosSeteados = this.vehiculosSeteados.filter(vehiculo => {
        return (
          vehiculo.Patente != item.Patente && vehiculo.Posicion != item.Posicion
        )
      })
      this.vehiculosFiltrados = this.vehiculosSeteados
    },

    handleInhabilitado(item) {
      this.vehiculosFiltrados = this.vehiculosFiltrados.filter(
        vehiculo => vehiculo.Patente != item.Patente
      )
    },

    async inhabilitar(item) {
      await this.$store
        .dispatch('usados/inhabilitarVehiculo', {
          patente: item.Patente,
          familia_id: item.Empresa_id
        })
        .then(res => {
          if (res.exito) {
            this.vehiculosFiltrados = this.vehiculosFiltrados.filter(
              vehiculo => vehiculo.Patente != item.Patente
            )
          } else {
            this.$store.dispatch('show_snackbar', {
              color: 'error',
              text: res.message
            })
          }
        })
    }
  },
  watch: {
    async archivo() {
      if (this.archivo.length > 0) {
        const reader = new FileReader()
        reader.onload = e => {
          const data = new Uint8Array(e.target.result)
          const workbook = read(data, { type: 'array' })
          const sheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[sheetName]
          const jsonData = utils.sheet_to_json(worksheet, { raw: true })

          let vehiculosModificados = jsonData.map(vehiculo => {
            let {
              Año: Anio,
              'Fecha alta': FechaDeAlta,
              'Precio de toma': PrecioDeToma,
              'Ex dueño': Ex_Duenio,
              'Costo de taller': CostoTaller,
              'Costo Financiero': costoFinanciero,
              Margen: margen,
              'Precio final': precioFinal,
              'Precio revista': precioRevista,
              Modelo: Modelo,
              Km: KM,
              Situacion,
              Empresa: Empresa_id,
              ...restoPropiedades
            } = vehiculo

            if (typeof CostoTaller == 'string') {
              CostoTaller = parseInt(CostoTaller)
            }

            typeof PrecioDeToma == 'string'
              ? (PrecioDeToma = parseInt(PrecioDeToma))
              : null
            typeof CostoTaller == 'string'
              ? (CostoTaller = parseInt(CostoTaller))
              : null
            typeof precioFinal == 'string' && precioFinal != ''
              ? (precioFinal = parseInt(precioFinal))
              : null
            typeof precioRevista == 'string' && precioRevista != ''
              ? (precioRevista = parseInt(precioRevista))
              : null

            typeof KM != 'number' ? (KM = Number(KM)) : null
            if (isNaN(KM)) {
              KM = 0
            }

            PrecioDeToma == undefined ? (PrecioDeToma = 0) : null
            precioFinal == undefined ? (precioFinal = null) : null
            precioRevista == undefined ? (precioRevista = null) : null
            Situacion == undefined ? (Situacion = '') : null
            Empresa_id == undefined || Empresa_id == ''
              ? (Empresa_id = null)
              : null
            Empresa_id == 'RADA' || Empresa_id == 'rada'
              ? (Empresa_id = 5)
              : null

            return {
              ...restoPropiedades,
              Anio,
              FechaDeAlta,
              PrecioDeToma,
              Ex_Duenio,
              CostoTaller,
              precioFinal,
              precioRevista,
              costoFinanciero,
              margen,
              Situacion,
              Empresa_id,
              Modelo,
              KM
            }
          })

          this.vehiculosImportados = vehiculosModificados

          this.$store.state.loading = true
          this.limpiarFiltro()
          this.buscar()

          this.vehiculosFiltrados.forEach(vehiculoFiltrado => {
            const patente = vehiculoFiltrado.Patente

            this.vehiculosImportados.forEach(vehiculoImportado => {
              if (vehiculoImportado.Patente === patente) {
                vehiculoFiltrado.Situacion = vehiculoImportado.Situacion
                vehiculoFiltrado.precioFinal = vehiculoImportado.precioFinal
                vehiculoFiltrado.precioRevista = vehiculoImportado.precioRevista
                vehiculoFiltrado.PrecioDeToma = vehiculoImportado.PrecioDeToma
                vehiculoFiltrado.Modelo = vehiculoImportado.Modelo
                vehiculoFiltrado.KM = vehiculoImportado.KM
                vehiculoFiltrado.Transmision = vehiculoImportado.Transmision
                vehiculoFiltrado.Motor = vehiculoImportado.Motor
                vehiculoFiltrado.Combustible = vehiculoImportado.Combustible
                vehiculoFiltrado.Puertas = vehiculoImportado.Puertas
                vehiculoFiltrado.costoFinanciero =
                  vehiculoImportado.costoFinanciero
                vehiculoFiltrado.margen = vehiculoImportado.margen

                vehiculoFiltrado.actualizado = true

                if (vehiculoImportado.Empresa_id === 5) {
                  vehiculoFiltrado.Empresa_id = vehiculoImportado.Empresa_id
                }
              }
            })
          })

          this.vehiculosFiltrados = this.vehiculosFiltrados.filter(
            vehiculo => vehiculo.actualizado
          )
          this.$store.dispatch('usados/editarUsado', this.vehiculosFiltrados)

          this.$store.state.loading = false

          this.sheet = false
          this.$swal.fire({
            icon: 'success',
            title: 'Los vehiculos se editaron correctamente'
          })
          this.archivo = []
        }
        reader.readAsArrayBuffer(this.archivo[0])
      }
    },

    'calculados.costoFinanciero': function (value) {
      const valor = Number(value)
      const porcentaje = valor / 100

      if (valor && valor <= 100 && valor >= 0) {
        this.vehiculosFiltrados.forEach(vehiculo => {
          const mesesxporcentaje = vehiculo.meses * porcentaje + 1
          vehiculo.costoFinanciero = vehiculo.PrecioDeToma * mesesxporcentaje
        })
      } else {
        this.vehiculosFiltrados.forEach(vehiculo => {
          vehiculo.costoFinanciero = null
        })
      }
    },
    'calculados.margen': function (value) {
      const valor = Number(value)
      const porcentaje = valor / 100 + 1

      if (
        value &&
        this.calculados.costoFinanciero != null &&
        valor <= 100 &&
        valor >= 0
      ) {
        this.vehiculosFiltrados.forEach(vehiculo => {
          if (vehiculo.costoFinanciero) {
            vehiculo.margen = vehiculo.costoFinanciero * porcentaje
          }
        })
      } else {
        this.vehiculosFiltrados.forEach(vehiculo => {
          vehiculo.margen = null
        })
      }
    },

    'filtro.switch': async function (value) {
      if (value) {
        this.$store.state.loading = true
        await this.$store
          .dispatch('usados/getVehiculosInhabilitados')
          .then(res => {
            this.vehiculosSeteados = []
            this.vehiculosFiltrados = []
            if (res.exito) {
              this.vehiculosSeteados = res.data
              this.vehiculosSeteados.forEach(vehiculo => {
                this.marcas.push(vehiculo.Marca.trim())
                this.marcasFiltradas = Array.from(new Set(this.marcas))
                vehiculo.costoFinanciero = null
                vehiculo.margen = null
                vehiculo.total = null

                this.empresas = [
                  {
                    id: 1,
                    nombre: 'DYCAR'
                  },
                  {
                    id: 2,
                    nombre: 'EURODYCAR'
                  },
                  {
                    id: 3,
                    nombre: 'GDB'
                  },
                  {
                    id: 5,
                    nombre: 'RADA'
                  }
                ]

                this.empresasFiltradas = this.empresas

                // Set Empresa de vehiculos
                switch (vehiculo.Empresa_id) {
                  case 1:
                    vehiculo.empresa = 'DYCAR'
                    break
                  case 2:
                    vehiculo.empresa = 'EURODYCAR'
                    break
                  case 3:
                    vehiculo.empresa = 'GDB'
                    break
                  case 5:
                    vehiculo.empresa = 'RADA'
                    break
                }
                // Set info
                const info = this.infoVehiculos.filter(
                  info => info.UsadoID === vehiculo.Usado_id
                )
                if (info.length > 0) {
                  vehiculo.memos = info[0].Memos.split(';')
                  vehiculo.montoTotal = format_money_round(info[0].MontoTotal)
                }
              })
              this.vehiculosFiltrados = this.vehiculosSeteados
            }
          })
        this.$store.state.loading = false
      } else {
        this.limpiarFiltro()
        this.vehiculosSeteados = []
        this.vehiculosFiltrados = []
        await this.getVehiculos()
      }
    }
  },
  computed: {
    ...mapState(['familias'])
  },

  filters: {
    formatMoney(value) {
      return format_money_round(value)
    }
  },
  components: {
    FileDropzone,
    BtnFiltro,
    ModalInfo,
    ModalEditar,
    SearchDataTable,
    BtnConfirmar
  }
}
</script>
