<template>
  <v-dialog v-model="dialog" max-width="800" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h3 v-if="vehiculo.costoFinanciero != null && vehiculo.margen != null">
          CF: {{ vehiculo.costoFinanciero | formatMoney }} - Margen:
          {{ vehiculo.margen | formatMoney }}
        </h3>
        <h3 v-else="vehiculo.costoFinanciero != null">
          {{ vehiculo.Marca }} - {{ vehiculo.Modelo }}
        </h3>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-2">
        <v-row class="mt-3">
          <v-col cols="12" sm="4">
            <text-field-money
              label="Precio de toma"
              v-model="data.PrecioDeToma"
              type="number"
              v-bind:properties="{
                prefix: '$',
                outlined: true,
                dense: true,
                hideDetails: true
              }"
            ></text-field-money>
          </v-col>

          <v-col cols="12" sm="3">
            <text-field-money
              v-model="data.PrecioRevista"
              type="number"
              v-bind:properties="{
                prefix: '$',
                outlined: true,
                dense: true,
                hideDetails: true
              }"
              dense
              label="Precio de revista"
            ></text-field-money>
          </v-col>
          <v-col cols="12" sm="3">
            <text-field-money
              v-model="data.PrecioFinal"
              type="number"
              v-bind:properties="{
                prefix: '$',
                outlined: true,
                dense: true,
                hideDetails: true
              }"
              dense
              label="Precio final"
            ></text-field-money>
          </v-col>
          <v-col sm="3" cols="12">
            <v-autocomplete
              clearable
              v-model="data.Empresa_id"
              :items="[{ nombre: 'RADA', id: 5 }]"
              item-value="id"
              item-text="nombre"
              hide-details
              outlined
              dense
              label="Empresa"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="data.Situacion"
              dense
              label="Situación"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              type="number"
              v-model="data.KM"
              dense
              label="Km"
              outlined
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
              v-model="data.Modelo"
              dense
              label="Modelo"
              outlined
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="3">
            <v-text-field
              v-model.trim="data.Color"
              dense
              label="Color"
              outlined
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="3">
            <v-text-field
              v-model.trim="data.Transmision"
              dense
              label="Transmision"
              outlined
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="6" sm="3">
            <v-text-field
              v-model.trim="data.Motor"
              dense
              label="Motor"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="3">
            <v-text-field
              v-model.trim="data.Combustible"
              dense
              label="Combustible"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              type="number"
              v-model.number="data.Puertas"
              dense
              label="Puertas"
              outlined
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="4" md="4">
            <v-switch
              :disabled="!permiso"
              v-model="data.habilitar"
              class="mt-n1 ml-4"
              label="Habilitar"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div style="display: flex; justify-content: end">
              <BtnConfirmar
                :loading="$store.state.loading"
                @action="editar()"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
import TextFieldMoney from '../../components/util/TextFieldMoney.vue'
import { format_money_round } from '../../util/utils'

export default {
  data() {
    return {
      data: {
        Empresa_id: null,
        PrecioDeToma: null,
        PrecioRevista: null,
        PrecioFinal: null,
        Situacion: '',
        KM: null,
        Usado_id: null,
        Modelo: '',
        Color: '',
        Transmision: '',
        Motor: '',
        Combustible: '',
        Puertas: null,
        habilitar: false
      },
      infoEditar: {}
    }
  },

  methods: {
    async editar() {
      this.infoEditar = {
        ...this.data,
        Usado_id: this.vehiculo.Usado_id
      }

      if (this.data.PrecioFinal != null) {
        const editar = []
        const objeto = {
          ...this.infoEditar
        }

        objeto.Transmision?.toUpperCase()

        editar.push(objeto)
        this.$store.state.loading = true
        await this.$store
          .dispatch('usados/editarUsado', editar)
          .then(async res => {
            if (res.exito == 1 && this.data.PrecioFinal != null) {
              if (this.permiso && this.data.habilitar) {
                await this.$store
                  .dispatch('usados/habilitarVehiculo', {
                    patente: this.vehiculo.Patente,
                    familia_id: this.vehiculo.Empresa_id,
                    posicion: this.vehiculo.Posicion
                  })
                  .then(res => {
                    if (res.exito) {
                      this.$emit('habilitado', this.vehiculo)
                    } else {
                      this.$store.dispatch('show_snackbar', {
                        color: 'error',
                        text: res.message
                      })
                    }
                  })
              } else if (this.permiso && !this.data.habilitar) {
                await this.$store
                  .dispatch('usados/inhabilitarVehiculo', {
                    patente: this.vehiculo.Patente,
                    familia_id: this.vehiculo.Empresa_id
                  })
                  .then(res => {
                    if (res.exito) {
                      this.$emit('inhabilitado', this.vehiculo)
                    } else {
                      this.$store.dispatch('show_snackbar', {
                        color: 'error',
                        text: res.message
                      })
                    }
                  })
              }

              this.dialog = false
              this.$emit('actualizarInfoEditar', this.infoEditar)
              this.$swal.fire({
                icon: 'success',
                title: res.message
              })
            } else {
              this.$store.dispatch('show_snackbar', {
                text: 'Debe ingresar valores en Precio de revista y Precio final',
                color: 'error'
              })
            }
          })
        this.$store.state.loading = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar Precio final',
          color: 'error'
        })
      }
    },

    async habilitarVehiculo(item) {
      await this.$store
        .dispatch('usados/habilitarVehiculo', {
          patente: item.Patente,
          familia_id: item.Empresa_id,
          posicion: item.Posicion
        })
        .then(res => {
          if (res.exito) {

            this.$emit('habilitado', item)
          } else {
            this.$store.dispatch('show_snackbar', {
              color: 'error',
              text: res.message
            })
          }
        })
    },

    async inhabilitarVehiculo(item) {
      await this.$store
        .dispatch('usados/inhabilitarVehiculo', {
          patente: item.Patente,
          familia_id: item.Empresa_id
        })
        .then(res => {
          if (res.exito) {
            this.$emit('inhabilitado', item)
          } else {
            this.$store.dispatch('show_snackbar', {
              color: 'error',
              text: res.message
            })
          }
        })
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  filters: {
    formatMoney(value) {
      return format_money_round(value)
    }
  },

  props: {
    value: Boolean,
    vehiculo: Object,
    habilitar: Boolean,
    permiso: Boolean
  },

  watch: {
    dialog(value) {
      if (!value) {
        this.data.Empresa_id = null
        this.data.PrecioDeToma = null
        this.data.Situacion = ''
        this.data.PrecioFinal = null
        this.data.PrecioRevista = null
        this.data.KM = null
        this.data.Color = ''
        this.data.Transmision = ''
        this.data.Motor = ''
        this.data.Combustible = ''
        this.data.Puertas = null
        this.data.habilitar = false

        this.infoEditar = {}
      } else {
        if (this.vehiculo.Empresa_id == 5) {
          this.data.Empresa_id = 5
        } else {
          this.data.Empresa_id = null
        }

        this.data.PrecioDeToma = this.vehiculo.PrecioDeToma
        this.data.Situacion = this.vehiculo.Situacion
        this.data.PrecioFinal = this.vehiculo.precioFinal
        this.data.PrecioRevista = this.vehiculo.precioRevista
        this.data.KM = this.vehiculo.KM
        this.data.Modelo = this.vehiculo.Modelo
        this.data.Color = this.vehiculo.Color
        this.data.Transmision = this.vehiculo.Transmision
        this.data.Motor = this.vehiculo.Motor
        this.data.Combustible = this.vehiculo.Combustible
        this.data.Puertas = this.vehiculo.Puertas
        this.data.habilitar = this.vehiculo.Habilitado
      }
    }
  },

  components: {
    BtnConfirmar,
    TextFieldMoney
  }
}
</script>
